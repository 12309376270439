.App {
  text-align: center;
  font-family: 'Indie Flower', cursive;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

body, button, input {
  font-size: 18px;
}

.container {
  background-color: white;
  padding: 50px;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #333;
  margin-bottom: 30px;
}

.fact-button, .keyword-input {
  margin: 0 10px; 
  padding: 10px 20px; 
}

.keyword-input {
  width: 240px; 
  border: 2px solid #81c784; 
  border-radius: 4px;
}

.get-useless-facts-button {
  background-color: #e75480;
  color: white; 
}

.get-gif-button {
  background-color: #fdd835; 
  color: black; 
}

.output-text {
  color: #32cd32; 
  margin-top: 30px;
  font-size: 18px;
  text-decoration: blueviolet;
}

.fact-button:hover {
  background-color: #0056b3;
  color: white;
}

.fact-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: #6a1b9a;
  background-color: rgba(255, 255, 255, 0.95); 
}